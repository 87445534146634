import { Center, Image, Link } from "@chakra-ui/react";
import * as React from "react"
import LayoverText from "../components/Widgets/LayoverText";
import Footer from "../components/Widgets/Footer";

import climberCourse from "../assets/Oversikt3.jpg"
import map from "../assets/kart.png"


function OpeningHoursPage() {
  
  return (
    <>
      <Center>
        <Image className="rounded-borders" src={climberCourse} w={['100%', '100%', '90%', '1000px']} marginTop={["-10px", "-10px", "40px"]} />
      </Center>
      <LayoverText shadow={true} offset={true} header="Åpningstider" subHeader="Moss klatresenter" content={[
        "Mandag - søndag: 06.00-00.00",
        "Senteret er åpent hver dag, hele året fra kl 06:00 - 24:00. Utenfor bemanningstidspunktene våre må du booke inngang via {$link_matchi_/prices} .",
        "",
        "Ordinære bemanningstider:",
        "• Mandag - torsdag: 17:00 - 21:00",
        "• Fredag: Ubemannet",
        "• Lørdag og søndag: 12:00 - 16:00",
      ]} />
      <LayoverText shadow={false} offset={false} subHeader="Værftsgata 22, 1511 Moss" content={[
      ]} />
       <Center paddingBottom={"60px"}>
        <Link href='https://goo.gl/maps/921BANczBa67RCT4A' isExternal>
          <Image src={map} w={['70%', '70%', '60%', '500px']} />
        </Link>
      </Center>
      <Footer />
    </>
  )
}

export default OpeningHoursPage;
