import * as React from "react"
import Footer from "../components/Widgets/Footer";
import LayoverText from "../components/Widgets/LayoverText";

function EventsPage() {
  return (
    <>
      <LayoverText shadow={false} offset={false} subHeader="Bli med på Buldrekarusellen hos Moss klatresenter!" content={[
        "Er du klar for å utfordre deg selv, ha det gøy og bli en del av Moss klatresenters klatrefellesskap? Da er Buldrekarusellen det perfekte arrangementet for deg!",
        "• Lørdag 02. november 2024",
        "• Lørdag 14. desember 2024",
        "",
        "Grunner til at du bør delta:",
        "{$bold_Vær-med-på-en-spennende-klatrekarusell:}",
        "Buldrekarusellen er en unik mulighet til å delta på tre sosiale arrangementer som kombinerer buldrekonkurranser, faglig påfyll og sosialt samvær. Det er den perfekte anledningen til å forbedre dine klatreferdigheter, lære noe nytt og ha det gøy sammen med likesinnede klatrere. Arrangementene er tilpasset barn og voksne.",
        "",
        "{$bold_Lavterskel-og-tilpasset-for-alle-nivåer:}",
        "Vi har deltakere på alle ferdighetsnivåer, så uansett om du er nybegynner eller erfaren klatrer, har du en plass i Buldrekarusellen. Velg din klasse (hvit/grønn, grønn/blå, blå/gul, gul/rød, rød/svart) basert på dine egne ferdigheter. Det er ingen press - bare utfordringer som passer akkurat for deg.",
        "",
        "{$bold_Spenning-og-konkurranse:}",
        "Konkurransen handler om å klare flest mulige buldre i din egen klasse. Du får ditt eget scorecard og krysser av for hver bulder du klarer. Hvem blir klassevinneren basert på poengsum? Det kan bli deg! Vi har dyno-konkurranser som utfordrer dine klatreferdigheter på en morsom måte!",
        "",
        "{$bold_Ingen-påmelding-nødvendig:}",
        "Du trenger ikke å melde deg på på forhånd. Det er bare å møte opp og være med på moroa! Så, hva venter du på? Sett datoen i kalenderen og bli med på Buldrekarusellen hos Moss klatresenter. Vi gleder oss til å se deg der!",
      ]} />
      <Footer />
    </>
  )
}

export default EventsPage;
