import { Center, Image } from "@chakra-ui/react";
import * as React from "react"
import Footer from "../components/Widgets/Footer";
import LayoverText from "../components/Widgets/LayoverText";

import climberCourse from "../assets/Oversikt-vegg4.jpg"

function CoursesPage() {

  return (
    <>
      <Center>
        <Image className="rounded-borders" src={climberCourse} w={['100%', '100%', '90%', '1000px']} marginTop={["-10px", "-10px", "40px"]} />
      </Center>
      <LayoverText shadow={true} offset={true} header="Kurs" subHeader="Nybegynnerkurs i buldring" content={[
        "Har du alltid hatt lyst til å prøve buldring, men aldri funnet den perfekte muligheten? Nå er sjansen her! Vårt introduksjonskurs gir deg inngangsverdiene du trenger for en super start på din buldrekarriere!",
        "",
        "{$bold_Kursdetaljer}",
        "{$bold_Hva:} Introduksjonskurs",
        "{$bold_Varighet:} To økter, hver på 1,5 timer",
        "{$bold_Når:}",
        "• Kurs settes opp ved forespørsel",
        "{$bold_Pris:} 499 kr",
        "{$bold_Påmelding:} Send navn, e-post, telefonnummer og ønsket dato til kontakt@mossklatresenter.no eller via vårt {$link_kontaktskjema._/about#contact}",
        "",
        "PS: Du MÅ ikke delta på et nybegynnerkurs for å buldre hos oss, men det gir deg trygghet og forutsetninger for å mestre raskere.",
      ]} />
      <LayoverText shadow={false} offset={true} subHeader="Treningsgrupper for barn og unge" content={[
        "Vi tilbyr faste treningsgrupper for barn og unge gjennom hele året. Hver gruppe består av 6-8 barn/unge som vil lære seg buldring og finne klatrevenner i Moss.",
        "Gruppene er for alle nivåer, enten man ønsker en introduksjon til buldring, målrettet trening, eller bare vil ha det gøy. Det viktigste for oss er at barna trives, opplever mestring og kan utfolde seg i klatreveggen!",
        "",
        "{$bold_Når:}",
        "• Barn 7 - 11 år: Tilbys enten mandager kl. 17:30-18:30 eller tirsdager kl. 17:30-18:30.",
        "• Barn 12 - 15 år: Tilbys enten mandager kl. 19:00-20:00 eller tirsdager kl. 19:00-20:00.",
        "",
        "{$bold_Påmelding:} Ønske om plass på kurset sendes til kontakt@mossklatresenter.no eller via vårt {$link_kontaktskjema._/about#contact}",
        "",
        "Det er begrenset antall plasser, så det er «førstemann til mølla» som gjelder. Når bekreftet plass er påmelding bindende.",
      ]} />
      
      <Footer />
    </>

  )
}

export default CoursesPage;
