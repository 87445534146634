import {
  Heading,
  Avatar,
  Box,
  Center,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

interface Props {
  name: string
  origin: string
  description: string
  imageRef: any
}



export default function ProfileCard(props: Props) {
  return (
    <Center py={20}>
      <Box
        maxW={'380px'}
        w={'full'}
        bg={useColorModeValue('white', 'gray.900')}
        p={0}
        textAlign={'center'}>
        <Avatar
          objectFit={"cover"}
          height={"300px"}
          width={"300px"}
          size={'4xl'}
          src={
            props.imageRef
          }
          mb={4}
          pos={'relative'}
        />
        <Heading fontSize={'2xl'} fontFamily={'body'}>
          {props.name + " "}
        </Heading>
        <Text fontWeight={600} color={'gray.500'} mb={0}>
          
        </Text>
        <Text fontWeight={600} color={'gray.500'} mb={2}>
          {props.origin}
        </Text>
        
        <Text
        as={"i"}
          textAlign={'left'}
          color={useColorModeValue('gray.700', 'gray.400')}
          px={3}>
          {'"' + props.description + '"'}
        </Text>
      </Box>
    </Center>
  );
}